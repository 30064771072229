<template>
  <evalmee-chip
    small
    label
    :outlined="outlined"
    :icon="icon"
    :color="color"
    v-tooltip="name"
    ellipsis
  >
    {{ name }}
  </evalmee-chip>
</template>

<script>
import EvalmeeChip from "../../../shared/evalmee_chip.vue"

export default {
  name: "QuizTagChip",
  components: { EvalmeeChip },
  props: {
    tag: { type: Object, required: true },
    outlined: { type: Boolean, default: false },
    icon: { type: String, default: null },
  },
  computed: {
    color() {
      return this.tag?.color ? `${this.tag?.color}` : null
    },
    name() { return this.tag?.name || "Unknown"},
  },
}
</script>

<style scoped>

</style>
